/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { stripslashes } from "../Helpers/SettingHelper";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import iconcategory from "../../common/images/icon-category.png";
import icondarkcategory from "../../common/images/icon-category-dark.png";

class MenuNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateMenu: [],
    };
  }

  // navigateMenuList() {
  //   let navigateMenuArr = this.props.navigateMenu;
  //   let navigateCommon = this.props.navigateCommon;

  //   var selectedNav = this.props.productState.selectedNavigation;
  //   var selectedNavigationFilter =
  //     this.props.productState.selectedNavigationFilter;

  //   var selectedMainCatId = this.props.productState.selectedMainCatId;

  //   if (Object.keys(navigateMenuArr).length > 0) {
  //     const mainMenu = navigateMenuArr.map((loaddata, index) => {
  //         if (loaddata.menu_type == "main") {
  //           return (
  //             <div
  //               key={index + 1}
  //               className={
  //                 selectedNavigationFilter !== ""
  //                   ? selectedNavigationFilter === loaddata.pro_cate_slug
  //                     ? "menu-list-intvl active"
  //                     : "menu-list-intvl"
  //                   : selectedNav === loaddata.pro_cate_slug
  //                   ? "menu-list-intvl active"
  //                   : "menu-list-intvl"
  //               }
  //             >
  //               <Link
  //                 to={
  //                   "/menu/category/" +
  //                   loaddata.pro_cate_slug
  //                 }
  //                 id={"menuNav_" + loaddata.pro_cate_slug}
  //                 className="menu-title-link"
  //                 title={loaddata.menu_custom_title}
  //               >
  //                 {stripslashes(loaddata.menu_custom_title.toLowerCase())}
  //               </Link>
  //             </div>
  //           );
  //         } else if (loaddata.menu_type == "sub") {
  //           return (
  //             <div
  //               key={index + 1}
  //               className={
  //                 selectedNav === loaddata.pro_subcate_slug
  //                   ? "menu-list-intvl active"
  //                   : "menu-list-intvl"
  //               }
  //             >
  //               <Link
  //                 to={"/products/subcategory/" + loaddata.pro_subcate_slug}
  //                 className="menu-title-link"
  //                 title={loaddata.menu_custom_title}
  //               >
  //                 {stripslashes(loaddata.menu_custom_title.toLowerCase())}
  //               </Link>
  //             </div>
  //           );
  //         }
  //     });
  //     setTimeout(function () {
  //       if ($(".owl-stage-outer .owl-stage .owl-item .owl-stage").length > 0) {
  //         $(".owl-stage-outer .owl-stage .owl-item .owl-stage").each(
  //           function () {
  //             $(this).parents(".owl-item").hide();
  //           }
  //         );
  //       }
  //     }, 100);
  //     return mainMenu;
  //   } else {
  //     return;
  //   }
  // }

goCategoryProducts(selectedNavigation) {
    $(document).find('.closeProductDetails').trigger('click');
    this.setState({ selectedNavigation: selectedNavigation });
      setTimeout(function(){
        if($(document).find("#pro_cat_" + selectedNavigation).length > 0){
            $("html, body").animate(
              {
                scrollTop: $(document).find("#pro_cat_" + selectedNavigation).offset().top - 200,
              },
              1000
            );
        }
      },600)
    $(".more-menu-parent, .more_categor_info").removeClass("active");
    $(".more_categor_info").hide();
  }


  navigateMenuList() {
    let navigateMenuArr = this.props.navigateMenu;
    // let navigateCommon = this.props.navigateCommon;

    // var selectedNav = this.props.productState.selectedNavigation;
    // var selectedNavigationFilter = this.props.productState.selectedNavigationFilter;

    var selectedMainCatId = this.props.productState.selectedMainCatId;

    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
        if (loaddata.menu_type == "main") {
          return (
            <li key={index}>
              <Link
                to={"/menu/category/" + loaddata.pro_cate_slug}
                data-ref={"/menu/category/" + loaddata.pro_cate_slug}
                onClick={(e) => {
                    this.goCategoryProducts(loaddata.pro_cate_slug);
                }}
                id={"menuNav_" + loaddata.pro_cate_slug}
                className="menu-title-link"
                title={loaddata.menu_custom_title}
              >
                {stripslashes(loaddata.menu_custom_title.toLowerCase())}
              </Link>
            </li>
          );
        }
      });
      return mainMenu;
    } else {
      return;
    }
  }

  render() {
    // var settingsTestimonial = {
    //   items: 6,
    //   nav: true,
    //   autoplay: false,
    //   dots: false,
    //   rewind: false,
    //   margin: 0,
    //   responsive: {
    //     0: {
    //       items: 2,
    //     },
    //     600: {
    //       items: 4,
    //     },
    //     900: {
    //       items: 5,
    //     },
    //     1200: {
    //       items: 6,
    //     },
    //   },
    // };

    let navigateMenuArr = this.props.navigateMenu;
    var selectedNav = this.props.productState.selectedNavigation;
    var selectedMenuTitle = "";
    if (selectedNav !== "") {
      if (Object.keys(navigateMenuArr).length > 0) {
        navigateMenuArr.map((loaddata, index) => {
          if (loaddata.pro_cate_slug === selectedNav) {
            selectedMenuTitle = stripslashes(
              loaddata.menu_custom_title.toLowerCase()
            );
          }
        });
      }
    }

    return (
      <>
        {/* {Object.keys(this.props.navigateMenu).length > 0 &&
         
            <OwlCarousel options={settingsTestimonial}>
              {this.navigateMenuList()}
            </OwlCarousel>
          )} */}
        <div className="foodbarn-search foodbarn-dflex">
          <div className="hcategory_sec">
            <a href="/" className="hcategory_selected_text disbl_href_action">
              {" "}
              {
                selectedMenuTitle
              } <span className="hcategory_trigger"></span>{" "}
            </a>
            <div className="hcategory_menu">
              {Object.keys(this.props.navigateMenu).length > 0 && (
                <ul className="hcategory_menulist">
                  {this.navigateMenuList()}
                </ul>
              )}
            </div>
          </div>
          <div className="input-sec">
            <input
              type="text"
              name="searchkey"
              placeholder="Search"
              onKeyUp={this.props.searchFunction}
              className="productsearch"
            />
            <a
              href="/"
              id="clearSearch"
              className="search_text_clear disbl_href_action"
            >
              X
            </a>
          </div>
        </div>
      </>
    );
  }
}

export default MenuNavigation;
